import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create({
  baseURL: '',
});

httpApi.interceptors.request.use((config) => {
  console.log(config);
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };

  return config;
});

httpApi.interceptors.response.use(
  (response) => {
    // Do something with the successful response (if needed)
    return response;
  },
  (error: AxiosError) => {
    console.log(error, error.response);
    throw new ApiError<ApiErrorData>(error?.response?.data?.message || error?.message, error?.response?.data);
  },
);

export interface ApiErrorData {
  message: string;
}
